<template>

  <div
    class="login login-signin-on login-3 d-flex flex-row-fluid"
    id="kt_login"
  >
    <div
      class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
      style="background-image: url('/assets/media/bg/bg-3.jpg');"
    >
      <div class="login-form text-center p-7 position-relative overflow-hidden">

        <div class="d-flex flex-center mb-10">
            <img
              :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
              class="h-80px"
              alt="Logo"
            />
        </div>

        <div class="login-signup">
          <div class="form-group d-flex flex-wrap flex-center">
              <div class="mt-5">
                <b-button v-b-modal.modal-select class="btn btn-lg btn-primary text-uppercase font-weight-bolder">conferma</b-button>
                <p class="mt-5"><b-button href="/assets/email/confirm-email.html" target="blank">Qui trovi html email</b-button></p>

                <!-- Seleziona come accedere -->
                <b-modal id="modal-select" size="lg" centered hide-header hide-backdrop hide-footer content-class="shadow" ok-only no-stacking>
                  <div class="row mt-10">
                      <div class="col-5 px-10">
                          <img
                              :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
                              class="h-60px"
                              alt="Logo"
                          />
                          <div class="mt-5">
                              <h1>BiPart</h1>
<!--                              <div class="text-muted font-weight-bold">-->
<!--                                <p class="lead"> Activate networks, change democracy!</p>-->
<!--                              </div>-->
                              <h2 class="mt-10 text-primary">Vuoi partecipare al processo?</h2>
                              <p>Per fare proposte, commentare, richiedere valutazioni, votare devi iscriverti al processo.</p>
                          </div>
                      </div>
                      <div class="col-7 border-left px-10">
                          <form class="form mt-5">
                              <!-- ACCEDI -->
                              <router-link to="" v-b-modal.modal-login>
                              <div class="d-flex align-items-center mb-4 bg-light-primary rounded p-5">
                                <span class="svg-icon svg-icon-warning mr-5">
                                  <span class="svg-icon svg-icon-lg">
                                    <img
                                        src="/assets/media/bipart/ico-patti.png"
                                        class="w-70px"
                                        alt="Logo"
                                    />
                                  </span>
                                </span>
                                <div class="d-flex flex-column flex-grow-1 mr-2">
                                  <a href="#" class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Accedi a tuo account</a>
                                  <span class="text-muted font-weight-bold">Sei già iscritto alla community di [NomeOrganzization]</span>
                                </div>
                              </div>
                              </router-link>
                              <!-- REGISTRATI -->
                              <router-link to="" v-b-modal.modal-register>
                              <div class="d-flex align-items-center mb-4 bg-light-primary rounded p-5">
                                <span class="svg-icon svg-icon-warning mr-5">
                                  <span class="svg-icon svg-icon-lg">
                                    <img
                                        src="/assets/media/bipart/ico-patti.png"
                                        class="w-70px"
                                        alt="Logo"
                                    />
                                  </span>
                                </span>
                                <div class="d-flex flex-column flex-grow-1 mr-2">
                                  <a href="#" class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Registrati alla community di [NomeOrganzization]</a>
                                  <span class="text-muted font-weight-bold">Testo da definire</span>
                                </div>
                              </div>
                              </router-link>
                              <router-link to="" v-b-modal.modal-authSelect>
                              <div class="d-flex align-items-center mb-4 bg-light-primary rounded p-5">
                                <span class="svg-icon svg-icon-warning mr-5">
                                  <span class="svg-icon svg-icon-lg">
                                    <img
                                        src="/assets/media/bipart/ico-patti.png"
                                        class="w-70px"
                                        alt="Logo"
                                    />
                                  </span>
                                </span>
                                <div class="d-flex flex-column flex-grow-1 mr-2">
                                  <a href="#" class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Partecipa al voto</a>
                                  <span class="text-muted font-weight-bold">Testo da definire</span>
                                </div>
                              </div>
                              </router-link>
                          </form>
                      </div>
                  </div>
                </b-modal>

                 <!-- LOGIN -->
                <b-modal id="modal-login" size="lg" centered hide-header hide-backdrop hide-footer content-class="shadow" ok-only no-stacking>
                    <div class="row mt-10">
                        <div class="col-5 px-10">
                            <img
                                :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
                                class="h-60px"
                                alt="Logo"
                            />
                            <div class="mt-5">
                                <h1>BiPart</h1>
<!--                                <div class="text-muted font-weight-bold">-->
<!--                                  <p class="lead"> Activate networks, change democracy!</p>-->
<!--                                </div>-->
                            </div>
                        </div>
                        <div class="col-7 border-left px-10">
                            <h2 class="text-primary">Accedi al tuo account</h2>
                            <form class="form mt-5 mb-5">
                              <!-- LOGIN -->
                              <div class="form-group mb-2">
                                 <div class="input-group">
                                    <input
                                    class="form-control h-auto form-control-solid py-4 px-8 is-valid"
                                    type="text"
                                    placeholder="username*"
                                    name="username"
                                    id="username"
                                    v-model="$v.form.username.$model"
                                    :state="validateState('username')"
                                    aria-describedby="username-live-feedback"/>
                                 </div>
                              </div>
                              <div class="form-group mb-5">
                                <div class="input-group">
                                  <input
                                  class="form-control h-auto form-control-solid py-4 px-8 is-valid"
                                  type="password"
                                  placeholder="Password*"
                                  name="password" />
                                </div>
                              </div>

                              <div class="text-muted font-weight-bold mb-5 ">Hai dimenticato la password
                                <a href="/metronic/demo1/custom/pages/login/login-3/signup.html" class="text-primary font-weight-bolder">Resettala <i class="fas fa-arrow-right text-primary icon-sm"></i></a>
                              </div>
                              <div class="form-group d-flex flex-wrap">
                                  <b-button class="btn btn-lg btn-default text-uppercase font-weight-bolder" v-b-modal.modal-select>indietro</b-button>
                                  <b-button class="ml-2 btn btn-lg btn-primary text-uppercase font-weight-bolder" v-b-modal.modal-authSelect>procedi</b-button>
                              </div>

                            </form>
                        </div>
                    </div>
                </b-modal>

                <!-- REGISTER -->
                <b-modal id="modal-register" size="lg" centered hide-header hide-backdrop hide-footer content-class="shadow" ok-only no-stacking>
                  <div class="row mt-10">
                      <div class="col-5 px-10">
                          <img
                              :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
                              class="h-60px"
                              alt="Logo"
                          />
                          <div class="mt-5">
                              <h1>BiPart</h1>
<!--                              <div class="text-muted font-weight-bold">-->
<!--                                <p class="lead"> Activate networks, change democracy!</p>-->
<!--                              </div>-->
                              <img
                                src="/assets/media/bipart/ico-patti.png"
                                class="w-100"
                                alt="Logo"
                            />
                          </div>
                      </div>
                      <div class="col-7 border-left px-10">
                          <h2 class="text-primary">Registra un account</h2>
                          <p class="font-weight-bolder">Entra a far parte della nostra community</p>
                          <form class="form mt-5 mb-5">
                            <!-- LOGIN -->
                            <div class="form-group mb-2">
                                <div class="input-group">
                                  <input
                                  class="form-control h-auto form-control-solid py-4 px-8 is-valid"
                                  type="text"
                                  placeholder="username*"
                                  name="username"
                                  id="username"
                                  v-model="$v.form.username.$model"
                                  :state="validateState('username')"
                                  aria-describedby="username-live-feedback"/>
                                </div>
                            </div>
                            <div class="form-group mb-2">
                              <div class="input-group">
                                <input
                                class="form-control h-auto form-control-solid py-4 px-8 is-valid"
                                type="password"
                                placeholder="Password*"
                                name="password" />
                              </div>
                            </div>

                            <div class="form-group mb-2">
                              <div class="input-group">
                                <input
                                class="form-control h-auto form-control-solid py-4 px-8 is-valid"
                                type="email"
                                placeholder="Email*"
                                name="Email" />
                              </div>
                            </div>

                            <div class="form-group mb-2">
                              <div class="input-group">
                                <input
                                class="form-control h-auto form-control-solid py-4 px-8  bg-light-primary"
                                type="text"
                                placeholder="Nome"
                                name="nome" />
                                <div class="input-group-append">
                                  <span class="input-group-text text-small text-muted">facoltativo</span>
                                </div>
                              </div>
                            </div>

                            <div class="form-group mb-5">
                              <div class="input-group">
                                <input
                                class="form-control h-auto form-control-solid py-4 px-8  bg-light-primary"
                                type="text"
                                placeholder="Cognome"
                                name="cognome" />
                                <div class="input-group-append">
                                  <span class="input-group-text text-small text-muted">facoltativo</span>
                                </div>
                              </div>
                            </div>

                            <div class="form-group mb-5 text-left">
                              <label class="checkbox m-0">
                              <input type="checkbox" name="agree" />I Agree the
                              <a href="#" class="font-weight-bold">terms and conditions</a>.
                              <span></span></label>
                              <div class="form-text text-muted text-center"></div>
                            </div>
                            <div class="form-group mb-5 text-left">
                              <label class="checkbox m-0">
                              <input type="checkbox" name="agree" />I Agree the
                              <a href="#" class="font-weight-bold">privacy policy</a>.
                              <span></span></label>
                              <div class="form-text text-muted text-center"></div>
                            </div>
                            <div class="form-group mb-5 text-left">
                              <label class="checkbox m-0">
                              <input type="checkbox" name="agree" />Subscribe newsletter
                              <span></span></label>
                              <div class="form-text text-muted text-center"></div>
                            </div>

                            <div class="form-group d-flex flex-wrap">
                                <b-button class="btn btn-lg btn-default text-uppercase font-weight-bolder" v-b-modal.modal-select>indietro</b-button>
                                <b-button class="ml-2 btn btn-lg btn-primary text-uppercase font-weight-bolder" v-b-modal.modal-authSelect>procedi</b-button>
                            </div>

                          </form>
                      </div>
                  </div>
                </b-modal>

                <!-- JUST ACTION -->


                <!-- Seleziona metodo di autenticazione -->
                <b-modal id="modal-authSelect" size="lg" centered hide-header hide-backdrop hide-footer content-class="shadow" ok-only no-stacking>
                    <div class="row mt-10">
                        <div class="col-5 px-10">
                            <img
                                :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
                                class="h-60px"
                                alt="Logo"
                            />
                            <div class="mt-5">
                                <h1>BiPart</h1>
<!--                                <div class="text-muted font-weight-bold">-->
<!--                                  <p class="lead"> Activate networks, change democracy!</p>-->
<!--                                </div>-->
                            </div>
                        </div>
                        <div class="col-7 border-left px-10">
                            <h2 class="text-primary">Verifica account</h2>
                            <p class="font-weight-bolder">Scegli come autenticarti</p>
                            <form class="form mt-5 mb-5">
                              <div class="form-group mb-5">
                                 <b-form-select v-model="selected" :options="options"></b-form-select>
                              </div>
                              <!-- NICE TO HAVE
                              quando selezione opzione da tendina mi mostra la scritta con gli attributi da validare a seguire: -->
                              <b-card class="mb-5">
                                <p class="mb-0"> Per autenticarti come cittadino devi verificare <b>Codice fiscale</b> e <b>Cellulare</b>. </p>
                              </b-card>

                              <div class="form-group d-flex flex-wrap">
                                  <b-button class="btn btn-lg btn-default text-uppercase font-weight-bolder" v-b-modal.modal-login>indietro</b-button>
                                  <b-button class="ml-2 btn btn-lg btn-primary text-uppercase font-weight-bolder" v-b-modal.modal-phone>procedi</b-button>
                              </div>

                            </form>
                        </div>
                    </div>
                </b-modal>

                 <!-- Verifica cellulare -->
                <b-modal id="modal-phone" size="lg" centered hide-header hide-backdrop hide-footer content-class="shadow" ok-only no-stacking>
                    <div class="row mt-10">
                        <div class="col-5 px-10">
                            <img
                                :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
                                class="h-60px"
                                alt="Logo"
                            />
                            <div class="mt-5">
                                <h1>BiPart</h1>
<!--                                <div class="text-muted font-weight-bold">-->
<!--                                  <p class="lead"> Activate networks, change democracy!</p>-->
<!--                                </div>-->
                            </div>
                        </div>
                        <div class="col-7 border-left px-10">
                            <h2 class="text-primary">Verifica account</h2>
                            <p class="font-weight-bolder">Per procedere devi verificare il cellulare</p>
                            <form class="form mt-5 mb-5">

                              <div class="form-group mb-5">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Prefisso
                                    </button>
                                    <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#">Prefisso 01</a>
                                    <a class="dropdown-item" href="#">Prefisso n...</a>
                                    </div>
                                  </div>
                                  <input
                                  class="form-control h-auto form-control-solid py-4 px-8 is-valid"
                                  type="text"
                                  placeholder="cellulare*"
                                  name="cellulare"
                                  id="cellulare"
                                  />
                                  <div class="valid-feedback">Cellulare corretto</div>
                                </div>
                              </div>
                              <div class="form-group d-flex flex-wrap">
                                  <b-button class="btn btn-lg btn-default text-uppercase font-weight-bolder" v-b-modal.modal-authSelect>indietro</b-button>
                                  <b-button class="ml-2 btn btn-lg btn-primary text-uppercase font-weight-bolder" v-b-modal.modal-sms>procedi</b-button>
                              </div>

                            </form>
                        </div>
                    </div>
                </b-modal>

                 <!-- Verifica Codice SMS OPT -->
                <b-modal id="modal-sms" size="lg" centered hide-header hide-backdrop hide-footer content-class="shadow" ok-only no-stacking>
                    <div class="row mt-10">
                        <div class="col-5 px-10">
                            <img
                                :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
                                class="h-60px"
                                alt="Logo"
                            />
                            <div class="mt-5">
                                <h1>BiPart</h1>
<!--                                <div class="text-muted font-weight-bold">-->
<!--                                  <p class="lead"> Activate networks, change democracy!</p>-->
<!--                                </div>-->
                            </div>
                        </div>
                        <div class="col-7 border-left px-10">
                            <h2 class="text-primary">Verifica account</h2>
                            <p class="font-weight-bolder">Abbiamo inviato un codice SMS di verifica al tuo cellulare.</p>
                            <form class="form mt-5 mb-5">

                              <div class="form-group mb-5">
                                <div class="input-group">
                                  <input
                                  class="form-control h-auto form-control-solid py-4 px-8 is-invalid"
                                  type="text"
                                  placeholder="Codice OPT*"
                                  name="Codice OPT"
                                  id="Codice"
                                  />
                                  <div class="invalid-feedback">Codice errato / codice scaduto</div>
                                </div>
                              </div>
                              <p class="mb-5"> <b>Non hai ricevuto il codice?</b> Prima verifica che il tuo cellulare sia corretto e poi <b-button v-b-modal.modal-cf class="btn-sm">richiedi un nuovo codice</b-button> </p>

                              <div class="form-group d-flex flex-wrap">
                                  <b-button class="btn btn-lg btn-default text-uppercase font-weight-bolder" v-b-modal.modal-phone>indietro</b-button>
                                  <b-button class="ml-2 btn btn-lg btn-primary text-uppercase font-weight-bolder" v-b-modal.modal-cf>procedi</b-button>
                              </div>

                            </form>
                        </div>
                    </div>
                </b-modal>

                 <!-- Verifica CF -->
                <b-modal id="modal-cf" size="lg" centered hide-header hide-backdrop hide-footer content-class="shadow" ok-only no-stacking>
                    <div class="row mt-10">
                        <div class="col-5 px-10">
                            <img
                                :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
                                class="h-60px"
                                alt="Logo"
                            />
                            <div class="mt-5">
                                <h1>BiPart</h1>
<!--                                <div class="text-muted font-weight-bold">-->
<!--                                  <p class="lead"> Activate networks, change democracy!</p>-->
<!--                                </div>-->
                            </div>
                        </div>
                        <div class="col-7 border-left px-10">
                            <h2 class="text-primary">Verifica account</h2>
                            <p class="font-weight-bolder">Inserisci il tuo codice fiscale</p>
                            <form class="form mt-5 mb-5">

                              <div class="form-group mb-5">
                                <div class="input-group">
                                  <input
                                  class="form-control h-auto form-control-solid py-4 px-8 is-valid"
                                  type="text"
                                  placeholder="Codice fiscale*"
                                  name="codice fiscale"
                                  id="cellulare"
                                  />
                                  <div class="valid-feedback">Codice fiscale valido</div>
                                </div>
                              </div>
                              <p class="mb-0 text-danger font-weight-bolder"> Hai inserito un codice fiscale diverso da quello già associato al tuo account?</p> <p><b>Verifica</b> che sia corretto o procedi per sostituirlo</p>.
                              <div class="form-group d-flex flex-wrap">
                                  <b-button class="btn btn-lg btn-default text-uppercase font-weight-bolder" v-b-modal.modal-opt>indietro</b-button>
                                  <b-button class="ml-2 btn btn-lg btn-primary text-uppercase font-weight-bolder" v-b-modal.modal-verify>procedi</b-button>
                              </div>

                            </form>
                        </div>
                    </div>
                </b-modal>

                <b-modal id="modal-verify" centered hide-header hide-backdrop hide-footer content-class="shadow" ok-only no-stacking>
                    <div class="text-center mt-10">
                      <img
                        :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
                        class="h-60px"
                        alt="Logo"
                      />
                      <p class="my-4 font-weight-bolder h4">Organization BiPart</p>
                      <h2 class="mt-5">Utente autenticato</h2>
                      <p class="lead mb-5">Adesso puoi partecipare al processo<br> <span class="font-weight-bolder">[nomeProcesso]</span></p>
                       <b-button class="btn btn-lg btn-primary text-uppercase font-weight-bolder" v-b-modal.modal-select>vota</b-button>
                    </div>
                </b-modal>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style lang="scss" scoped>
  .spinner.spinner-right {
    padding-right: 3.5rem !important;
  }
  </style>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import {
  LOGIN, LOGOUT, SET_HEADER_TOKEN
} from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import store from "@/core/services/store";

export default {
  mixins: [validationMixin],
  name: "register-advanced",
  data() {
    return {
      // errors: [],
      requireConditions: false,
      term_and_conditions: false,
      privacy:false,
      emailExists: false,
      email: '',

      // Remove this dummy login info
      form: {
        username: "",
        password: "",
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters([
      "getOrganization",
      "currentUser"
    ]),
    isDisabled: function(){
      return !this.privacy || !this.term_and_conditions;
    }
  },
  mounted() {
    this.$store.dispatch(LOGOUT);
  },
  validations: {
    form: {
      username: {
        required
      },
      password: {
        required,
        minLength: minLength(3)
      },
    },
  },
  methods: {
    validateEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    resetForm() {
      this.form = {
        username: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    onSubmitconditions() {

      if (this.email && !this.validateEmail(this.email)) {
        Swal.fire("Warning", "Invalid email", "info");
        return false;
      }

      // Aggiorno il profilo dell'utente con le accettazioni
      setTimeout(() => {
        let acceptData = {
          "id": this.currentUser.id,
          "termsConditionsAccepted": true,
          "privacyPolicyAccepted": true,
          "email": this.email
        }
        new Promise((resolve, reject) => {
          ApiService.put(this.$apiResource + "/users/mandatory-flags", acceptData)
          .then(() => {
            console.log('registrato');
            resolve(1);
          })
          .catch(() => {
            reject(0);
          });
        });

        // Invio l'utente alla home
        this.$router.push({ name: "home" });
        Swal.fire('Success', 'Registazione accettazione "Privacy" e "Termini e condizioni"', 'info')
      }, 500);

    },

    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const username = this.$v.form.username.$model;
      const password = this.$v.form.password.$model;

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let credentials = {
        username: username,
        password: password,
        // selectedOrganizationId: 1,
        selectedOrganizationDomain: this.$currentDomain,
        fetchEnabledOnly: true
      };

      // L'utente carmelo vede anche i processi disabilitati
      if (username == "carmelo") {
        // credentials.fetchEnabledOnly = false;
      }

      let loginResult = await this.$store.dispatch(LOGIN, credentials);
      submitButton.classList.remove("spinner", "spinner-light", "spinner-right");

      if ( loginResult.headers.token == "BadCredentialsException" ) {
        Swal.fire("Warning", "Invalid credential", "info");
      } else {

        // Swal.fire('Success', 'Autenticazione riuscita', 'success')
        // this.$router.push({name: "home"});
        /* */
        const userId = jwt_decode(loginResult.headers.token).id;

        await new Promise((resolve, reject) => {
          // setTimeout( () => {
            store.dispatch(SET_HEADER_TOKEN)
            var endpoint = this.generateUrl("/users/mandatory-flags/" + userId, false);
            //generateUrl is done
            ApiService.get(this.$apiResource + endpoint)
            .then(res => {
              if (res.data.privacyPolicyAccepted && res.data.termsConditionsAccepted) {
                Swal.fire('Success', 'Autenticazione riuscita', 'success')
                this.$router.push({name: "home"});
              } else {
                this.emailExists = (res.data.email) ? true : false;
                this.email = res.data.email;
                this.requireConditions = true;
              }
            })
            .catch(() => {
              Swal.fire('Errore', 'Si è verificato un errore', 'error')
              reject(0);
            });

          // }, 500);
        });
        /* */
      }
    },
  }
};
</script>
